import styles from './product.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { More } from '../../components/more/More';
import { Link } from 'react-router-dom';
import graphiteImage from '../../images/product-graphite.jpg';
import { OrderForm } from '../../components/form/Form';
import { Seo } from '../../helpers/Seo';
import { Certificate } from '../../components/certificate/Certificate';
import trgEaPreview from '../../images/trg-ea-preview.jpg';
import trgEa from '../../documents/certificates/trg-ea-certificate.pdf';

export function Graphite() {
    return (
        <>
            <article className={styles.product}>
                <Breadcrumbs />
                <div className={styles.productContainer}>
                    <h1 className={styles.title}>Термо расширяющийся графит (EG-250)</h1>
                    <div className={styles.container}>
                        <img
                            className={styles.image}
                            src={graphiteImage}
                            alt="Терморасширяющийся графит в мешках"
                        />
                        <div className={styles.infoContainer}>
                            <div className={styles.infoTable}>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}>
                                        <span className={styles.bold}>Производитель:</span></p>
                                    <p className={styles.infoTableRowContent}>Китай</p>
                                </div>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}><span className={styles.bold}>Фасовка и комплектация:</span>
                                    </p>
                                    <p className={styles.infoTableRowContent}>25 кг (мешок)</p>
                                </div>
                                <p className={styles.summary}>Итоговая стоимость заказа согласовывается
                                    индивидуально</p>
                            </div>
                            <OrderForm product='Терморасширяющийся графит' />
                        </div>
                    </div>
                </div>
                <div className={styles.tabsOverlay}>
                    <Tabs
                        className={styles.tabsContainer}
                        selectedTabClassName={styles.active}
                        selectedTabPanelClassName={styles.content}
                    >
                        <TabList className={styles.tabs}>
                            <Tab className={styles.name}>О товаре</Tab>
                            <Tab className={styles.name}>Доставка и оплата</Tab>
                            <Tab className={styles.name}>Хранение</Tab>
                            <Tab className={styles.name}>Сертификация</Tab>
                        </TabList>

                        <TabPanel>
                            <div className={styles.text}>
                                <p>Товар поставляется в мешках фасовкой 25 кг.</p>
                                <p>Страна производитель – Китай.</p>
                                <p><span className={styles.bold}>Графит окисленный терморасширяющийся</span> –
                                    уникальный во
                                    многих
                                    отношениях материал. Практически
                                    на 100% он состоит из чистого углерода, что и делает его востребованным в
                                    промышленных отраслях. Материал имеет незначительные включения других химических
                                    элементов, однако они не влияют на конечные характеристики.</p>
                                <p>Уникальность данного материала обусловлена его хорошей пластичностью. Им успешно
                                    заменяют уплотнители и набивки из других материалов, представленных на рынке.
                                    Многие производители отказываются от асбестосодержащих материалов, фторопласта и
                                    резины в пользу терморасширяющегося графита.</p>
                                <More
                                    href='/info/graphite'
                                    caption='Читать полностью'
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Доставка может осуществляться, как за счет компании ООО «Меламин Трейд», так и на
                                    условиях
                                    самовывоза со склада компании, расположенного по адресу: г. Щелково, ул. Заводская,
                                    д.2-65,
                                    строение 1, помещение 2, офис 1</p>
                                <p>Основное время работы склада: c 9-00 до 18-00 в рабочие дни. Самовывоз в другое время
                                    можно
                                    согласовывать отдельно.</p>
                                <More
                                    href='/contacts'
                                    caption='Посмотреть на карте'
                                />
                                <p>Оплата производится по <Link
                                    className={styles.link}
                                    to='/payment'
                                >реквизитам</Link></p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Мешки с терморасширяющимся графитом хранят в закрытых помещениях, предохраняя от
                                    действия
                                    прямых
                                    солнечных
                                    лучей и влаги. Гарантийный срок хранения сухого состава — 2 года с даты
                                    изготовления.</p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={`${styles.text} ${styles.certificationContainer}`}>
                                <div className={styles.certificateWrapper}>
                                    <Certificate
                                        preview={trgEaPreview}
                                        name='E&A (CHINA) UNION International Trade Co., LTD'
                                        file={trgEa}
                                    />
                                </div>
                                <p className={styles.certified}>Вся поставляемая продукция сертифицирована в
                                    соответствии с
                                    действующим законодательством
                                    Российской Федерации.</p>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </article>
            <Seo
                title='Термо расширяющийся графит (ТРГ)'
                description='Терморасширяющийся графит (ТРГ) оптом в России. Доставка, самовывоз. Терморасширяющийся графит (ТРГ) практически на 100% он состоит из чистого углерода, что и делает его востребованным в промышленных отраслях.'
                keywords='термо расширяющийся графит, ТРГ, терморасширяющийся графит'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
