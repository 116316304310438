import styles from './contacts.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import placeMarkImage from '../../images/component-maps-placemark.svg';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function Contacts() {
    useVH();
    return (
        <>
            <article className={styles.contacts}>
                <Breadcrumbs />
                <h1 className={styles.title}>Контакты</h1>
                <div className={styles.contactsContainer}>
                    <ul className={styles.list}>
                        <li><span className={styles.name}>Адрес:</span><p className={styles.address}>140250, Московская область. городской округ Воскресенск, г. Белоозёрский, ул. Молодежная, д. 24, кв. 66, c 9:00 до 18:00</p></li>
                        <li>
                            <span className={styles.name}>Email:</span><a
                            className={styles.link}
                            href='mailto:order@melaminetrade.ru'
                        >order@melaminetrade.ru</a>
                        </li>
                        <li><span className={styles.name}>Телефон:</span><p>+7 (967) 050-40-90</p></li>
                    </ul>
                    <YMaps>
                        <Map
                            state={{ center: [55.459986, 38.441462], zoom: 17 }}
                            className={styles.map}
                        >
                            <Placemark
                                geometry={[55.911512, 38.024303]}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: placeMarkImage,
                                    iconImageSize: [30, 40]
                                }}
                            />
                        </Map>
                    </YMaps>
                </div>
            </article>
            <Seo
                title='Контакты'
                description='Email: order@melaminetrade.ru, тел.: +7 (967) 050-40-90'
                keywords='меламин трейд контакты'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}