import styles from './product.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { More } from '../../components/more/More';
import { Link } from 'react-router-dom';
import melamineImage from '../../images/product-melamine.jpg';
import { OrderForm } from '../../components/form/Form';
import { Seo } from '../../helpers/Seo';
import { Certificate } from '../../components/certificate/Certificate';
import melamineAnhuiPreview from '../../images/melamine-anhui-preview.jpg';
import melamineAnhui from '../../documents/certificates/melamine-anhui-certificate.pdf';
import melamineHuaqiangPreview from '../../images/melamine-huaqiang-preview.jpg';
import melamineHuaqiang from '../../documents/certificates/melamine-huaqiang-certificate.pdf';
import melamineShanxiPreview from '../../images/melamine-shanxi-preview.jpg';
import melamineShanxi from '../../documents/certificates/melamine-shanxi-certificate.pdf';

export function Melamine() {
    return (
        <>
            <article className={styles.product}>
                <Breadcrumbs />
                <div className={styles.productContainer}>
                    <h1 className={styles.title}>Меламин</h1>
                    <div className={styles.container}>
                        <img
                            className={styles.image}
                            src={melamineImage}
                            alt="Меламин в мешках"
                        />
                        <div className={styles.infoContainer}>
                            <div className={styles.infoTable}>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}>
                                        <span className={styles.bold}>Производитель:</span></p>
                                    <p className={styles.infoTableRowContent}>Китай</p>
                                </div>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}><span className={styles.bold}>Фасовка и комплектация:</span>
                                    </p>
                                    <p className={styles.infoTableRowContent}>25 кг (мешок)</p>
                                </div>
                                <p className={styles.summary}>Итоговая стоимость заказа согласовывается
                                    индивидуально</p>
                            </div>
                            <OrderForm product='Меламин' />
                        </div>
                    </div>
                </div>
                <div className={styles.tabsOverlay}>
                    <Tabs
                        className={styles.tabsContainer}
                        selectedTabClassName={styles.active}
                        selectedTabPanelClassName={styles.content}
                    >
                        <TabList className={styles.tabs}>
                            <Tab className={styles.name}>О товаре</Tab>
                            <Tab className={styles.name}>Доставка и оплата</Tab>
                            <Tab className={styles.name}>Хранение</Tab>
                            <Tab className={styles.name}>Сертификация</Tab>
                        </TabList>

                        <TabPanel>
                            <div className={styles.text}>
                                <p>Товар поставляется в мешках фасовкой 25 кг.</p>
                                <p>Страна производитель – Китай.</p>
                                <p><span className={styles.bold}>Меламин</span> (микронизированный) является
                                    невоспламеняющимся,
                                    не имеющим
                                    запаха, низкотоксичным и неопасным химическим веществом.
                                    Меламин необходим для получения органических смол, дубящих веществ, гербицидов и
                                    красителей, а также в других процессах промышленного органического синтеза. Основная
                                    его масса используется в изготовлении меламин-формальдегидных смол – соединений с
                                    очень широким применением.</p>
                                <p>Меламин является важным компонентом при изготовлении пластмасс, плит МДФ,
                                    лакокрасочных материалов и вспучивающихся огнезащитных покрытий. Варианты его
                                    применения исчисляются сотнями.</p>
                                <More
                                    href='/info/melamine'
                                    caption='Читать полностью'
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Доставка может осуществляться, как за счет компании ООО «Меламин Трейд», так и на
                                    условиях
                                    самовывоза со склада компании, расположенного по адресу: г. Щелково, ул. Заводская,
                                    д.2-65,
                                    строение 1, помещение 2, офис 1</p>
                                <p>Основное время работы склада: c 9-00 до 18-00 в рабочие дни. Самовывоз в другое время
                                    можно
                                    согласовывать отдельно.</p>
                                <More
                                    href='/contacts'
                                    caption='Посмотреть на карте'
                                />
                                <p>Оплата производится по <Link
                                    className={styles.link}
                                    to='/payment'
                                >реквизитам</Link></p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Мешки меламина хранят в закрытых помещениях, предохраняя от действия прямых
                                    солнечных
                                    лучей и влаги. Гарантийный срок хранения сухого состава — 2 года с даты
                                    изготовления.</p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={`${styles.text} ${styles.certificationContainer}`}>
                                <div className={styles.certificateWrapper}>
                                    <Certificate
                                        preview={melamineAnhuiPreview}
                                        name='ANHUI JINHE INDUSTRIAL Co., Ltd'
                                        file={melamineAnhui}
                                    />
                                    <Certificate
                                        preview={melamineHuaqiangPreview}
                                        name='HUAQIANG CHEMICAL GROUP CO., LTD'
                                        file={melamineHuaqiang}
                                    />
                                    <Certificate
                                        preview={melamineShanxiPreview}
                                        name='SHANXI YANGMEI FENGXI FERTILIZER INDUSTRY (GROUP) CO., LTD.'
                                        file={melamineShanxi}
                                    />
                                </div>
                                <p className={styles.certified}>Вся поставляемая продукция сертифицирована в
                                    соответствии с
                                    действующим законодательством
                                    Российской Федерации.</p>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </article>

            <Seo
                title='Меламин'
                description='Меламин оптом в России. Доставка, самовывоз. Меламин (микронизированный) является невоспламеняющимся, не имеющим запаха, низкотоксичным и неопасным химическим веществом.'
                keywords='меламин, меламин микронизированный'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
