import styles from './certificates.module.css'
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Certificate } from '../../components/certificate/Certificate';

import melamineAnhuiPreview from '../../images/melamine-anhui-preview.jpg';
import melamineHuaqiangPreview from '../../images/melamine-huaqiang-preview.jpg';
import melamineShanxiPreview from '../../images/melamine-shanxi-preview.jpg';

import pfaEaPreview from '../../images/pfa-ea-preview.jpg';
import pfaKylinPreview from '../../images/pfa-kylin-preview.jpg';

import trgEaPreview from '../../images/trg-ea-preview.jpg';

import melamineAnhui from '../../documents/certificates/melamine-anhui-certificate.pdf';
import melamineHuaqiang from '../../documents/certificates/melamine-huaqiang-certificate.pdf';
import melamineShanxi from '../../documents/certificates/melamine-shanxi-certificate.pdf';
import pfaEa from '../../documents/certificates/pfa-ea-certificate.pdf';
import pfaKylin from '../../documents/certificates/pfa-kylin-certificate.pdf';
import trgEa from '../../documents/certificates/trg-ea-certificate.pdf';


import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function Certificates() {
    useVH();
    return (
        <>
            <article className={styles.certificates}>
                <Breadcrumbs />
                <h1 className={styles.title}>Сертификаты</h1>
                <p className={styles.text}>Вся поставляемая продукция сертифицирована в соответствии с действующим
                    законодательством РФ.</p>
                <div className={styles.certificatesContainer}>
                    <Certificate
                        preview={melamineAnhuiPreview}
                        name='ANHUI JINHE INDUSTRIAL Co., Ltd'
                        file={melamineAnhui}
                    />
                    <Certificate
                        preview={melamineHuaqiangPreview}
                        name='HUAQIANG CHEMICAL GROUP CO., LTD'
                        file={melamineHuaqiang}
                    />
                    <Certificate
                        preview={melamineShanxiPreview}
                        name='SHANXI YANGMEI FENGXI FERTILIZER INDUSTRY (GROUP) CO., LTD.'
                        file={melamineShanxi}
                    />
                    <Certificate
                        preview={pfaEaPreview}
                        name='E&A (CHINA) UNION International Trade Co., LTD'
                        file={pfaEa}
                    />
                    <Certificate
                        preview={pfaKylinPreview}
                        name='SHIFANG CHANGFENG CHEMICAL'
                        file={pfaKylin}
                    />
                    <Certificate
                        preview={trgEaPreview}
                        name='E&A (CHINA) UNION International Trade Co., LTD'
                        file={trgEa}
                    />
                </div>
            </article>
            <Seo
                title='Сертификаты'
                description='Вся поставляемая продукция сертифицирована в соответствии с действующим законодательством РФ.'
                keywords='антипирены, сертификат качества, сертифицированный меламин, сертифицированный полифосфат аммония, сертифицированный ТРГ'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
