import React, { useState } from 'react';
import styles from './form.module.css';
import { object, string } from 'yup';
import axios from 'axios';
import { Formik } from 'formik';

const API_URL = 'https://api.melaminetrade.ru/wp-json';
const FORM_ID = '8';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^\+?\d*$/;

const dataFromForm = (json) => {
    try {
        const data = new FormData();

        for (let key in json) {
            data.append(key, json[key]);
        }
        return data
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function OrderForm({ product }) {
    const [state, setState] = useState('');
    let isSubmitError = state === 'Ошибка!';
    let isSubmitSuccess = state === 'Успешно!';

    const validationSchema = object({
        formName: string()
            .min(2, 'Минимум 2 символа')
            .required('Укажите имя или название организации'),
        formEmail: string()
            .min(7, 'Минимум 7 символов')
            .matches(EMAIL_REGEX, 'Некорректная почта')
            .required('Укажите электронную почту'),
        formPhone: string()
            .matches(PHONE_REGEX, 'Некорректный номер телефона')
            .min(10, 'Минимум 10 цифр')
            .required('Укажите телефон')
    });

    return (
        <Formik
            initialValues={{
                formHidden: `${product}`,
                formName: '',
                formEmail: '',
                formPhone: '',
                formMessage: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const onFormSubmit = async () => {

                    try {
                        await axios({
                            url: `${API_URL}/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                            },
                            method: 'POST',
                            data: dataFromForm(values)
                        })
                        setState('Успешно!');
                        setSubmitting(false);
                        resetForm();
                        setTimeout(() => {
                            setState(null);
                        }, 5000)
                    } catch (error) {
                        setState('Ошибка!');
                        setSubmitting(false);
                        setTimeout(() => {
                            setState(null);
                        }, 5000)
                    }
                };

                void onFormSubmit();
            }}
        >
            {({
                  dirty,
                  isValid,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  handleReset
              }) => (
                <form
                    className={styles.form}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <input
                        type='hidden'
                        defaultValue={product}
                        name="formHidden"
                        id="formHidden"
                    />
                    <fieldset className={styles.fieldset}>
                        <div className={styles.field}>
                            <input
                                className={errors.formName && touched.formName ? styles.error : styles.input}
                                type="text"
                                name="formName"
                                id="formName"
                                placeholder={'ФИО/Организация *'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.formName}
                            />
                            <div className={styles.errorContainer}>
                                {errors.formName && touched.formName
                                    ? <span
                                        className={styles.errorMessage}
                                    >{errors.formName}</span>
                                    : null}
                            </div>
                        </div>

                        <div className={styles.field}>
                            <input
                                className={errors.formEmail && touched.formEmail ? styles.error : styles.input}
                                type="email"
                                name="formEmail"
                                id="formEmail"
                                placeholder={'Эл. почта *'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.formEmail}
                            />
                            <div className={styles.errorContainer}>
                                {errors.formEmail && touched.formEmail
                                    ? <span
                                        className={styles.errorMessage}
                                    >{errors.formEmail}</span>
                                    : null}
                            </div>
                        </div>

                        <div className={styles.field}>
                            <input
                                className={errors.formPhone && touched.formPhone ? styles.error : styles.input}
                                type="text"
                                name="formPhone"
                                id="formPhone"
                                placeholder={'Телефон *'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.formPhone}
                            />
                            <div className={styles.errorContainer}>
                                {errors.formPhone && touched.formPhone
                                    ? <span
                                        className={styles.errorMessage}
                                    >{errors.formPhone}</span>
                                    : null}
                            </div>
                        </div>
                    </fieldset>
                    <div className={styles.controls}>
                        <fieldset className={styles.fieldset}>
                            <div className={styles.field}>
                                    <textarea
                                        name="formMessage"
                                        id="formMessage"
                                        placeholder={'Сообщение'}
                                        rows="1"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.formMessage}
                                        className={errors.formMessage && touched.formMessage ? "form__input_invalid" : styles.textarea}
                                    />
                                <div className={styles.errorContainer}>
                                    {errors.formMessage && touched.formMessage
                                        ? <span
                                            className={styles.errorMessage}
                                        >{errors.formMessage}</span>
                                        : null}
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className={styles.fieldset}>
                            <div className={styles.buttonsContainer}>
                                <button
                                    type="button"
                                    className={styles.reset}
                                    onClick={handleReset}
                                    disabled={!dirty || isSubmitting}
                                >
                                    {'Очистить'}
                                </button>
                                <button
                                    type="submit"
                                    disabled={!(dirty && isValid)}
                                    className={isSubmitError ? styles.sendError : styles.submit}
                                >
                                    {isSubmitting
                                        ? 'Отправка ...'
                                        : state ? state : 'Отправить запрос'}
                                </button>
                            </div>
                            <div className={styles.state}>
                                {isSubmitError ?
                                    <p className={styles.stateErrorMessage}>
                                        Не удалось отправить запрос. Попробуйте позже или позвоните по телефону +7 (967)
                                        050-40-90
                                    </p>
                                    : isSubmitSuccess ?
                                        <p className={styles.stateSuccessMessage}>
                                            Запрос успешно отправлен. Наши менеджеры свяжутся с Вами в ближайшее время.
                                        </p> : null}
                            </div>
                        </fieldset>
                    </div>
                </form>)}
        </Formik>
    );
}

