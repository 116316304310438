import styles from './main.module.css';
import { products } from '../../data/products';
import { Product } from '../../components/product/Product';
import { More } from '../../components/more/More';
import flagsImage from '../../images/main-flags.jpg';
import infoImage from '../../images/main-info.jpg';
import { Seo } from '../../helpers/Seo';

export function Main() {

    return (
        <>
            <main className={styles.main}>
                <section className={styles.homescreen}>
                    <div className={styles.homescreenContainer}>
                        <h1 className={styles.homescreenTitle}>Поставка и продажа качественных антипиренов из Китая в
                            Россию</h1>
                        <div className={styles.homescreenProducts}>
                            {
                                Object.values(products).map((product, key) => (
                                    <Product
                                        product={product}
                                        key={key}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </section>
                <article className={styles.about}>
                    <div className={styles.aboutContainer}>
                        <div className={styles.aboutWrapper}>
                            <h2 className={styles.subtitle}>Почему выбирают Меламинтрейд?</h2>
                            <p className={styles.caption}>Компания ООО «Меламин Трейд» является поставщиком
                                сертифицированных российских и импортных
                                антипиренов.</p>
                            <p className={styles.caption}>Наша цель – обеспечение качественными антипиренами российских
                                потребителей данного сырья по
                                рыночным ценам.</p>
                            <More
                                href='/about'
                                caption='Читать полностью'
                            />
                        </div>
                        <img
                            className={styles.aboutImage}
                            src={flagsImage}
                            alt="Флаги Китая и России"
                        />
                    </div>
                </article>
                <article className={styles.info}>
                    <div className={styles.infoContainer}>
                        <img
                            className={styles.infoImage}
                            src={infoImage}
                            alt="Антипирен порошок, огонь"
                        />
                        <div className={styles.infoWrapper}>
                            <h2 className={styles.subtitle}>Антипирены</h2>
                            <p className={styles.caption}><span className={styles.boldText}>Антипирены</span> — это
                                химические вещества или соединения, ингибиторы
                                реакции горения. Они
                                препятствуют воспламенению и возгоранию, не дают пламени распространяться, способствуют
                                самостоятельному затуханию огня.</p>
                            <p className={styles.caption}><span className={styles.boldText}>Согласно официальному определению антипирен</span> —
                                это вещества либо
                                смеси, которые должны снижать
                                горючесть материалов, имеющих органическое происхождение.
                            </p>
                            <p className={styles.caption}>К таковым относятся: древесина, полимерные соединения, ткани
                                натуральные и синтетические,
                                композитные материалы на основе древесных стружек, бумажно-слоистый пластик и многое
                                другое,
                                на что пламень воздействует очень быстро.
                                Например, для сухой древесины, на которую не была нанесена специальная краска или
                                пропитка,
                                температура начала реакции пиролиза составляет всего порядка 450 градусов. </p>
                            <p className={styles.caption}>В таких обстоятельствах пожар будет скоротечным и абсолютно
                                разрушительным. </p>
                            <More
                                href='/info/fire-retardant'
                                caption='Читать полностью'
                            />
                        </div>
                    </div>
                </article>
            </main>
            <Seo
                title='Поставка и продажа качественных антипиренов из Китая в Россию'
                description='Компания ООО «Меламин Трейд» является поставщиком сертифицированных российских и импортных антипиренов.'
                keywords='меламин, антипирены, антипирены оптом, меламин оптом, трг, терморасширяющийся графит, полифосфат аммония'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
