import styles from './navigation.module.css';
import { NavLinks } from '../navlinks/NavLinks';
import { Modal } from '../modal/Modal';
import { useModal } from '../../hooks/useModal';
import { OrderForm } from '../form/Form';

export function Navigation() {
    const { isVisible, toggleModal } = useModal();
    // noinspection JSValidateTypes
    return (
        <nav className={styles.navigation}>
            <ul className={styles.container}>
                <div className={styles.desktopNav}>
                    <NavLinks />
                </div>
                <li className={styles.time}>c 9:00 до 18:00</li>
                <li>
                    <button
                        className={styles.button}
                        onClick={toggleModal}
                        disabled={isVisible}
                    >Обратный звонок
                    </button>
                    <Modal
                        isVisible={isVisible}
                        hideModal={toggleModal}
                        title='Оставьте Ваши данные и мы перезвоним'
                    >
                        <OrderForm product='Общий запрос' />
                    </Modal>
                </li>
            </ul>
        </nav>
    )
}