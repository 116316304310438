import styles from './product.module.css';
import { useLocation, Link } from 'react-router-dom';

export function Product({ product }) {
    const { pathname } = useLocation();
    const productsPagePath = '/products';
    let isProductPage = pathname === productsPagePath;

    return (
        <article className={!isProductPage ? styles.product : styles.productProductsPage}>
            <div>
                <h3 className={styles.title}>{product.title}</h3>
                <p className={styles.caption}>{product.caption}</p>
            </div>
            <img
                className={!isProductPage ? styles.image : styles.imageProductsPage}
                src={!isProductPage ? product.mainImage : product.productsImage}
                alt={product.title}
            />
            <div className={styles.wrapper}>
                {isProductPage &&
                    <ul className={styles.specs}>
                        <li>Фасовка:</li>
                        <li className={styles.caption}>25 кг (мешок)</li>
                    </ul>
                }
                <Link
                    className={!isProductPage ? styles.link : styles.button}
                    to={product.link}
                >Подробнее</Link>
            </div>
        </article>
    )
}