import styles from './article.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Seo } from '../../helpers/Seo';

export function PolyphosphateArticle() {
    return (

        <>
            <article className={styles.article}>
                <Breadcrumbs />
                <h1 className={styles.title}>Полифосфат аммония</h1>
                <div className={styles.articleContainer}>
                    <p><span className={styles.bold}>Полифосфат аммония</span> – это неорганическая соль фосфорной
                        кислоты.
                    </p>
                    <p>Полифосфат аммония применяется в различных областях промышленности: автомобильной, лакокрасочной,
                        химической, мебельной и даже пищевой. Наиболее широко он применяется в качестве пламязамедляющей
                        добавки, т. е. является эффективным антипиреном и применяется при производстве огнезащитных
                        красок,
                        лаков, пропиток, мастик, пластиков, оболочек электрических кабелей и т. д. Одним из основных
                        достоинств полифосфата аммония как антипирена является его экологическая безопасность и
                        нетоксичность. Полифосфат аммония не содержит галогены, являющиеся очень ядовитыми и токсичными
                        веществами для людей, животных и окружающей природы.</p>
                    <p><span className={styles.bold}>Полифосфаты аммония, используемые в качестве антипиренов производятся двух видов:</span> с
                        кристаллической
                        фазой I и степенью полимеризации менее 1000, а также с кристаллической фазой II и степенью
                        полимеризации более 1000. Полифосфаты аммония с кристаллической фазой I имеют линейную структуру
                        с
                        варьирующейся длиной цепи, обладают более низкой температурой разложения и более высокой
                        растворимостью в воде. Полифосфаты аммония с кристаллической фазой II имеют аналогичную, но
                        более
                        сложную и разветвленную структуру с более высоким молекулярным весом, более высокой термической
                        стабильностью и более низкой растворимостью в воде. </p>
                    <p>Полифосфат аммония – это основной антипирен в составе огнезащитной краски, его доля может
                        доходить до
                        25 % от общего состава. Под воздействием температуры пламени огнезащитная краска вспучивается,
                        потом
                        на покрытом основном материале (как правило, это строительная конструкция металлическая иди
                        деревянная) образуется полифосфатная кислота после обезвоживания и из-за нагрева.</p>
                    <p>Таким образом, на поверхности органического соединения образовывается углеродистая плёнка,
                        одновременно выделяется большое количество негорючих газов, соответственно образуется толстый
                        пенный
                        теплоизоляционный слой, который изолирует материал от воздуха, нагрева и не допускает развития
                        пламени. </p>
                    <p className={styles.bold}>В образовавшемся пенном слое присутствуют: </p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Углеродистый материал - источник углерода (углевод,
                            пентаэритрит,
                            дипентаэритрит, крахмал и др.).
                        </li>
                        <li className={styles.listItem}>Катализатор - источник фосфорной кислоты (фосфор).</li>
                        <li className={styles.listItem}>Полифосфат - содержание полифосфата значительное, но меньше
                            количества полифосфата, разлагающегося при температуре разложения углеродистого соединения;
                            углеродистая плёнка, образующаяся на защищаемом материале.
                        </li>
                        <li className={styles.listItem}>Пенообразователь - источник газа.</li>
                    </ol>
                    <p>Пенообразователь образует газы, за счёт которых углеродистая плёнка вспенивается с образованием
                        толстого углеродистого слоя. В качестве пенообразователя применяются меламин, циановый аммоний,
                        мочевина и др.</p>
                    <h2 className={styles.subtitle}>Физико-химические показатели</h2>
                    <div
                        className={styles.table}
                        role='table'
                    >
                        <div className={styles.row}>
                            <p className={styles.bold}>Наименование показателей:</p>
                            <p className={styles.bold}>Значение показателей:</p>
                        </div>
                        <div className={styles.row}>
                            <p>Содержание фосфора (P), %:</p>
                            <p>не менее 31,0</p>
                        </div>
                        <div className={styles.row}>
                            <p>Содержание азота (N), %:</p>
                            <p>не менее 14,0</p>
                        </div>
                        <div className={styles.row}>
                            <p>Содержание воды (H2O), %:</p>
                            <p>не более 0,25</p>
                        </div>
                        <div className={styles.row}>
                            <p>Степень полимеризации:</p>
                            <p>не менее 1000</p>
                        </div>
                        <div className={styles.row}>
                            <p>Температура разложения, °С:</p>
                            <p>не менее 270</p>
                        </div>
                        <div className={styles.row}>
                            <p>Растворимость в воде при 25 °С, г/100 см³:</p>
                            <p>не более 0,5</p>
                        </div>
                        <div className={styles.row}>
                            <p>Вязкость при 25 °С в 10 % суспензии, мПа*с:</p>
                            <p>не более 100</p>
                        </div>
                        <div className={styles.row}>
                            <p>Плотность при 25 °С, г/см³:</p>
                            <p>1,9</p>
                        </div>
                        <div className={styles.row}>
                            <p>pH при 25 °С в 10 % суспензии:</p>
                            <p>5,5 - 7,5</p>
                        </div>
                        <div className={styles.row}>
                            <p>Средний размер частиц, мкм:</p>
                            <p>15</p>
                        </div>
                        <div className={styles.row}>
                            <p>Размер частиц менее 50 мкм, %:</p>
                            <p>не менее 97</p>
                        </div>
                    </div>
                    <p><span className={styles.bold}>Полифосфат аммония</span> - это белый, мелкозернистый,
                        кристаллический
                        порошок, не имеющий запаха.</p>
                    <p><span className={styles.bold}>Упаковка:</span> мешок, 25 кг.</p>
                </div>
            </article>
            <Seo
                title='Статьи: Полифосфат аммония'
                description='Полифосфат аммония применяется в различных областях промышленности: автомобильной, лакокрасочной, химической, мебельной и даже пищевой.'
                keywords='полифосфат аммония информация'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}