import styles from './about.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import aboutImage from '../../images/about-image.jpg';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function About() {
    useVH();
    return (
        <>
            <article className={styles.about}>
                <Breadcrumbs />
                <h1 className={styles.title}>О компании</h1>
                <div className={styles.aboutContainer}>
                    <div className={styles.info}>
                        <p className={styles.caption}>Компания ООО «Меламин Трейд» является поставщиком
                            сертифицированных
                            российских и импортных
                            антипиренов.
                        </p>
                        <p className={styles.caption}>Наша цель – обеспечение качественными антипиренами российских
                            потребителей данного сырья по
                            рыночным
                            ценам.
                        </p>
                        <p className={styles.caption}>Прогрессивный подход к изучению поставляемого сырья позволяет
                            контролировать качество
                            поставляемого
                            товара.</p>
                    </div>
                    <img
                        className={styles.image}
                        src={aboutImage}
                        alt="Переплетённые руки"
                    />
                </div>
            </article>
            <Seo
                title='О компании'
                description='Наша цель – обеспечение качественными антипиренами российских потребителей данного сырья по рыночным ценам.'
                keywords='меламин трейд ооо компания'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}