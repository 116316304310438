import styles from './certificate.module.css';
import { Download } from '../download/Download';

export function Certificate({ preview, name, file }) {
    return (
        <div className={styles.certificate}>
            <img
                className={styles.image}
                src={preview}
                alt={name}
            />
            <p className={styles.name}>{name}</p>
            <Download
                file={file}
                caption='Скачать'
            />
        </div>
    )
}