import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import styles from './offcanvas.module.css';

export function OffCanvas({ isVisible, setIsVisible, children }) {
    const location = useLocation();
    const [currentLocation] = useState(location);

    useEffect(() => {
        if (location !== currentLocation && isVisible === true) {
            setIsVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, currentLocation, setIsVisible]);

    return isVisible
        ? createPortal(
            <div
                className={styles.offcanvas}

            >
                {children}
            </div>,
            document.body,
        )
        : null;
}