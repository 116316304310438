import polyphosphateMainPageImage from '../images/main-polyphosphate.png';
import melamineMainPageImage from '../images/main-melamine.png';
import graphiteMainPageImage from '../images/main-graphite.png';
import polyphosphateProductsPageImage from '../images/products-polyphosphate.png';
import melamineProductsPageImage from '../images/products-melamine.png';
import graphiteProductsPageImage from '../images/products-graphite.png';

export const products = {
    polyphosphate: {
        title: 'Полифосфат аммония',
        caption: 'Неорганическая соль фосфорной кислоты',
        mainImage: polyphosphateMainPageImage,
        productsImage: polyphosphateProductsPageImage,
        link: '/products/polyphosphate'
    },
    melamine: {
        title: 'Меламин',
        caption: 'Невоспламеняющееся, не имеющее запаха, низко токсичное и неопасное химическое вещество',
        mainImage: melamineMainPageImage,
        productsImage: melamineProductsPageImage,
        link: '/products/melamine'
    },
    graphite: {
        title: 'Графит',
        caption: 'Окисленный терморасширяющийся модифицированный природный графит, очищенный от смол и неорганических наполнителей',
        mainImage: graphiteMainPageImage,
        productsImage: graphiteProductsPageImage,
        link: '/products/graphite'
    }
}