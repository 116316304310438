import React from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.css';
import useVH from 'react-vh';

export function Modal({ isVisible, hideModal, children, title }) {
    useVH();
    return isVisible
        ? createPortal(
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <div>
                        <h2 className={styles.title}>{title}</h2>
                        {children}
                    </div>
                    <button className={styles.close} onClick={hideModal} />
                </div>
            </div>,
            document.body,
        )
        : null;
}