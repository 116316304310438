import styles from './article.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Seo } from '../../helpers/Seo';

export function FireRetardantArticle() {
    return (
        <>
            <article className={styles.article}>
                <Breadcrumbs />
                <h1 className={styles.title}>Антипирены</h1>
                <div className={styles.articleContainer}>
                    <p><span className={styles.bold}>Антипирены</span> — это химические вещества или соединения,
                        ингибиторы
                        реакции горения. Они препятствуют
                        воспламенению и возгоранию, не дают пламени распространяться, способствуют самостоятельному
                        затуханию огня.
                    </p>
                    <p><span className={styles.bold}>Согласно официальному определению антипирен</span> — это вещества
                        либо
                        смеси, которые должны снижать
                        горючесть материалов, имеющих органическое происхождение. К таковым относятся: древесина,
                        полимерные
                        соединения, ткани натуральные и синтетические, композитные материалы на основе древесных
                        стружек,
                        бумажно-слоистый пластик и многое другое, на что пламень воздействует очень быстро.
                    </p>
                    <p>Например, для сухой древесины, на которую не была нанесена специальная краска или пропитка,
                        температура начала реакции пиролиза составляет всего порядка 450 градусов.
                        В таких обстоятельствах пожар будет скоротечным и абсолютно разрушительным.
                    </p>
                    <p>Стоит ли сомневаться в том, что эксплуатация сооружений из подобных легковоспламеняющихся
                        материалов
                        представляет собой опасность не только для их хозяев, но и для всех окружающих природных и
                        хозяйственных объектов, соответственно, для людей.</p>
                    <p>Своевременное применение антипиренов
                        в критический момент задержит распространение огня на начальной стадии, позволит произвести
                        спасательные мероприятия и продержаться до приезда профессиональной пожарной команды.</p>
                    <h2 className={styles.subtitle}>Как работает антипирен?</h2>
                    <p>Все существующие антипирены преследуют одну и ту же цель — максимально эффективно подавить
                        процесс
                        возгорания и распространения пожара. В зависимости от химического состава конкретного антипирена
                        он
                        оказывает различное воздействие на материалы при их горении:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Антипирены во время горения поглощают тепло и разлагаются на
                            негорючие вещества.
                        </li>
                        <li className={styles.listItem}>Оказывают влияние на способ разложения материала под
                            воздействием
                            пламени, например, коксируют
                            твердую массу и образовывают негорючие газы.
                        </li>
                        <li className={styles.listItem}>В конденсированной и газовой фазах воспламенения оказывают
                            тормозящее воздействие.
                        </li>
                        <li className={styles.listItem}>Образовывает на поверхностях объекта теплозащитный слой
                            вспененного
                            кокса.
                        </li>
                        <li className={styles.listItem}>Способствует образованию продуктов горения по типу сажи.</li>
                    </ol>
                    <p>Антипирены это вещества, которые могут работать активно и пассивно. Первые вступают
                        в реакцию с огнем и температурами, вторые – ведут себя инертно по отношению к горению.
                    </p>
                    <p>Антипирен может иметь неорганическое, органическое и смешанное происхождение. Это может быть соль
                        металла, гидроксид, специально обработанные парафин или графит и другое. Из-за конфликтов с
                        экологией многие антипирены перестают быть актуальными, когда оценивается не только их действие,
                        но
                        и его последствия.
                    </p>
                    <p><span className={styles.bold}>К безопасным можно отнести традиционные антипирены</span> —
                        гидроксиды
                        магния и алюминия. Под воздействием
                        высоких температур они выделяют водяной пар, который
                        в результате эндотермической реакции охлаждается ниже температуры, которая требуется для
                        поддержки
                        горения. Естественно, не поддерживается и процесс пиролиза, зато катализируется образование
                        твердого
                        защитного слоя.
                    </p>
                    <p><span className={styles.bold}>Все более популярными становятся экологичные антипирены нового поколения:</span> легкоплавкое
                        стекло,
                        органические эко-антипирены.
                    </p>
                    <p>Особенного внимания заслуживают так называемые интумесцентные антипирены,
                        в которых при повышении температуры происходит объемное изменение — многократное
                        терморасширение.
                        Покрытия, включающие эти вещества, успешно заменяют старые толстые термоизолирующие и не
                        пропускающие кислород асбестовые штукатурки, минеральную вату, вспученный вермикулита.
                    </p>
                    <p>Все это было эффективно, но достаточно дорого, а главное — существенно утяжеляло конструкцию,
                        отрицательно влияя на прочность. Механизм действия интумесцентного антипирена прост и
                        эффективен:
                        при нагревании внутри тонкого, примерно 2мм, покрытия образуются газовые пузырьки, за счет чего
                        покрытие расширяется в 10-40, а иногда и 100 раз, образуется так называемый пенококс,
                        теплоизолирующий и негорючий.</p>
                    <h2 className={styles.subtitle}>Характеристики антипиренов</h2>
                    <p>Самые главные характеристики антипиренов — это основные параметры, которые устанавливают для них
                        правила ГОСТ. А именно:
                    </p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Эффективность защиты, то есть способность подавлять реакции
                            горения
                            и тления. Для примера: чтобы
                            доказать, что состав соответствует 2-й группе огнезащиты, защищаемые им древесные материалы
                            должны выдержать как минимум 15 минут без возгорания
                            в температурном интервале +650 - +700 градусов.
                        </li>
                        <li className={styles.listItem}>Теплоизоляционные свойства. Антипирены поглощают тепло активно и
                            много,
                            в результате чего материал разогревается гораздо медленнее.
                        </li>
                        <li className={styles.listItem}>Отсутствие взаимодействия с материалами охраняемых поверхностей
                            и их
                            покрытиям.
                        </li>
                        <li className={styles.listItem}>Способность защищать металлы от коррозии.</li>
                        <li className={styles.listItem}>Нулевая токсичность антипирена в нормальных условиях и продуктов
                            его
                            распада при пожаре, а также
                            экологическая безопасность.
                        </li>
                        <li className={styles.listItem}>Долгий срок эксплуатации при сохранении всех полезных
                            характеристик.
                            Самый короткий срок
                            получается у солевых антипиренов, наносимых на внешние поверхности, так как их стойкость
                            сильно
                            зависит от осадков. Максимальный срок службы огнезащиты после нанесения таких растворов на
                            внешние поверхности составляет не более 5 лет. Хотя чаще повторная обработка требуется уже
                            через
                            2 года.
                        </li>
                    </ol>
                    <p>В зависимости от состава антипирена, он может реализовываться в виде порошка, спрессованных
                        таблеток,
                        аэрозолей, суспензий, жидкостей.</p>
                    <p>Больше половины производимых препаратов составляют неорганические вещества, созданные на основе:
                    </p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}> Борной кислоты.</li>
                        <li className={styles.listItem}>Полифосфата.</li>
                        <li className={styles.listItem}>Cульфата аммония.</li>
                        <li className={styles.listItem}>Буры.</li>
                        <li className={styles.listItem}>Гидроксидов алюминия, магния.</li>
                        <li className={styles.listItem}>красного фосфора и пр.</li>
                    </ol>
                    <p>Перечисленные вещества отличаются хорошей адгезией, высокой температурой разложения, образованием
                        при
                        раскладывании негорючих веществ. Например, из гидроксида алюминия образуется тугоплавкий оксид и
                        вода. Оба этих компонента снижают температуру области горения, а вода, дополнительно, формирует
                        облако из пара, препятствующего подачу новых порций воздуха. Некоторые вещества могут проявлять
                        антисептические действия.</p>
                    <h2 className={styles.subtitle}>Где применяется антипирен?</h2>
                    <p>Вернемся к официальному определению антипирена. Из него следует, что именно конструкции, при
                        создании
                        которых использовались органические материалы, требуют применения покрытий, содержащих
                        антипирены.
                        Дерево и его производные горят хорошо, а полимеры без специальных добавок еще лучше, поэтому для
                        них
                        огнезащитные средства должны использоваться по максимуму: для дерева сразу же после первичной
                        обработки желательны антипиреновые пропитки, а для полимеров еще в процессе их получения —
                        антипиреновые добавки. Это правильные меры, но не вполне достаточные. Чтобы пожарная
                        безопасность
                        была на уровне, необходимы и последующие огнезащитные покрытия.
                    </p>
                    <p>Минеральные поверхности, сооруженные, например, из кирпичей, бетона или пеноблоков, вряд ли
                        способны
                        к возгоранию, однако окутавшее их пламя может привести к образованию трещин, которые влияют на
                        целостность конструкции и повышают опасность обрушения. То есть антипирен необходим и для
                        минеральной поверхности. Огнезащита для металлических конструкций (опорных, коммуникационных)
                        также
                        важна, особенно если подразумеваются крупные промышленные объекты или многоэтажные общественные
                        здания.
                    </p>
                    <p>Первое место в мире по объему использования в качестве антипиренов занимают гидроокись алюминия и
                        гидроокись магния. Они дешевы, безопасны при работе, не выделяют опасных веществ при горении,
                        ими
                        легко пользоваться. Принцип их действия основан на получении в результате пиролиза воды. Дыма
                        при
                        этом образуется очень мало.</p>
                    <p>Потребление гидроокиси магния и алюминия в качестве огнезащитных добавок увеличивается с каждым
                        годом. Особенно на этом настаивают природоохранные организации, т.к. гидроксиды почти не
                        оказывают
                        на окружающую среду негативного
                        воздействия.
                    </p>
                    <p>Антипирены на основе гидроокиси алюминия и магния применяются для производства термо- и
                        реактопластов, эластомеров, полиолефинов, ПВХ, полипропиленов; полимеров, использующихся в
                        изготовлении линолеумов и ковровых покрытий, шлангов, кровельных материалов, пожаробезопасной
                        изоляции для кабелей, конструкционных пластиков и многого другого.
                    </p>
                    <p>Гидроокись магния стоит дороже гидроксида алюминия, но у нее есть существенное преимущество —
                        высокая
                        термостойкость.
                    </p>
                    <p>В качестве наполнителей-антипиренов применяются галогеносодержащие соединения,
                        в состав которых входят хлор или бром. Соединения с йодом слишком дороги, а со фтором —
                        способствуют
                        разрушению материала и оборудования. При горении эти вещества разлагаются и активно вступают в
                        реакции с газообразными продуктами пиролиза полимера, связывая их и лишая пламя топлива. При
                        этом
                        уменьшается образование дыма и токсичных газов.
                    </p>
                    <p>Бромосодержащие антипирены лучше и безопаснее, а хлоросодержащие — дешевле. Для увеличения
                        эффективности галогеносодержащих антипиренов обычно используется триоксид сурьмы, который
                        разлагается с выделением негорючих газов.
                    </p>
                    <p>Весьма популярны антипирены на основе фосфора и его производных. Применяются красный фосфор,
                        фосфаты
                        аммония, неорганические и органические фосфаты, полифосфаты, фосфонаты и пр.
                    </p>
                    <p>Защитное действие фосфоросодержащих антипиренов состоит в образовании на поверхности вспененной
                        коксообразной корки. Если антипирен дополнительно содержит и галоген, то его эффективность
                        возрастает за счет вывода горючих газов из реакции.
                        Фосфоросодержащие добавки используются при изготовлении полиуретановых пен, полиэфиров,
                        полиамидов,
                        ПВХ, полистиролов, полиэтиленов.
                    </p>
                    <p>В качестве антипиренов применяются также борная кислота, бура, Антипиренысульфат и хлорид
                        аммония,
                        меламин и его производные, силикаты натрия (жидкое стекло), борат и хлорид цинка,
                        кремнийорганические соединения, вспенивающийся графит, соли молибдена и ванадия и многие другие.
                    </p>
                    <p>Меламин и его производные в основном применяются при производстве полиуретановых и полиамидных
                        пен и
                        термопластов. При горении они разлагаются с большим поглощением тепла и выделением негорючих
                        газов.
                        К достоинствам этого типа добавок следует отнести невысокую стоимость, безопасность для
                        окружающей
                        среды и отсутствие коррозионного эффекта по отношению к оборудованию.
                    </p>
                    <p>Борат цинка добавляют в ПВХ, полиолефины, полиамиды, эпоксидные смолы, эластомеры. Обычно его
                        используют в смеси с оксидом сурьмы, красным фосфором или гидроокисью алюминия.
                    </p>
                    <p>Кремнийорганические соединения и вспенивающийся графит при горении образуют на поверхности
                        плотный
                        защитный слой. Кремнийорганические антипирены обычно добавляют в полиуретаны, графит — в термо-
                        и
                        реактопласты.
                    </p>
                    <p>Борная кислота, бура, силикаты натрия используются в качестве ингредиента в строительных смесях,
                        для
                        пропитки тканей и древесины.</p>
                </div>
            </article>
            <Seo
                title='Статьи: Антипирены'
                description='Антипирены это вещества либо смеси, которые должны снижать горючесть материалов, имеющих органическое происхождение.'
                keywords='антипирены информация'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}