// noinspection ES6CheckImport
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ScrollToTop } from '../scroll-to-top/ScrollToTop';
import { Header } from '../header/Header';
import { Main } from '../../pages/main/Main';
import { About } from '../../pages/about/About';
import { Payment } from '../../pages/payment/Payment';
import { Certificates } from '../../pages/certificates/Certificates';
import { Info } from '../../pages/info/Info';
import { FireRetardantArticle } from '../../pages/article/FireRetardant';
import { MelamineArticle } from '../../pages/article/Melamine';
import { PolyphosphateArticle } from '../../pages/article/Polyphosphate';
import { GraphiteArticle } from '../../pages/article/Graphite';
import { Contacts } from '../../pages/contacts/Contacts';
import { Footer } from '../footer/Footer';
import { Products } from '../../pages/products/Products';
import { Polyphosphate } from '../../pages/product/Polyphosphate';
import { Melamine } from '../../pages/product/Melamine';
import { Graphite } from '../../pages/product/Graphite';
import { Terms } from '../../pages/agreement/Terms';
import { Privacy } from '../../pages/agreement/Privacy';
import { NotFound } from '../../pages/404/NotFound';

function App() {
    const location = useLocation();
    const [currentLocation] = useState(location);

    useEffect(() => {
        window.ym(87741143, 'hit', location.pathname);
    }, [location, currentLocation]);

    return (
        <>
            <Header />
            <ScrollToTop />
            <Routes>
                <Route
                    path='*'
                    element={<NotFound />}
                />
                <Route
                    path='/'
                    element={<Main />}
                />
                <Route
                    path='/about'
                    element={<About />}
                />
                <Route
                    path='/products'
                    element={<Products />}
                />
                <Route
                    path='/products/polyphosphate'
                    element={<Polyphosphate />}
                />
                <Route
                    path='/products/melamine'
                    element={<Melamine />}
                />
                <Route
                    path='/products/graphite'
                    element={<Graphite />}
                />
                <Route
                    path='/payment'
                    element={<Payment />}
                />
                <Route
                    path='/certificates'
                    element={<Certificates />}
                />
                <Route
                    path='/info'
                    element={<Info />}
                />
                <Route
                    path='/info/fire-retardant'
                    element={<FireRetardantArticle />}
                />
                <Route
                    path='/info/melamine'
                    element={<MelamineArticle />}
                />
                <Route
                    path='/info/polyphosphate'
                    element={<PolyphosphateArticle />}
                />
                <Route
                    path='/info/graphite'
                    element={<GraphiteArticle />}
                />
                <Route
                    path='/contacts'
                    element={<Contacts />}
                />
                <Route
                    path='/terms-and-conditions'
                    element={<Terms />}
                />
                <Route
                    path='/privacy-policy'
                    element={<Privacy />}
                />
            </Routes>
            <Footer />
        </>

    );
}

export default App;