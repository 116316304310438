import styles from './header.module.css';
import { Top } from '../top/Top';
import { Navigation } from '../navigation/Navigation';

export function Header() {
    return (
        <header className={styles.header}>
            <Top />
            <Navigation />
        </header>
    )
}