import styles from './navlinks.module.css';
import { NavLink } from 'react-router-dom';

export function NavLinks() {
    return (
        <>
            <li><NavLink
                to='/about'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >О компании</NavLink></li>
            <li><NavLink
                to='/products'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >Товары</NavLink></li>
            <li><NavLink
                to='/payment'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >Доставка и оплата</NavLink></li>
            <li><NavLink
                to='/certificates'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >Сертификаты</NavLink></li>
            <li><NavLink
                to='/info'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >Полезная информация</NavLink></li>
            <li><NavLink
                to='/contacts'
                className={({ isActive }) => (isActive ? styles.active : styles.link)}
            >Контакты</NavLink></li>
        </>
    )
}