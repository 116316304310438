import './agreement.module.css';
import styles from '../article/article.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Seo } from '../../helpers/Seo';

export function Terms() {
    return (
        <>
            <article className={styles.article}>
                <Breadcrumbs />
                <h1 className={styles.title}>Пользовательское соглашение</h1>
                <div className={styles.articleContainer}>
                    <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО «Меламин
                        Трейд» (Администрация) с одной стороны и пользователем сайта с
                        другой.</p>
                    <p>Сайт https://melaminetrade.ru не является средством массовой информации.</p>
                    <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</p>
                    <p className={styles.bold}>Если Вы не согласны с условиями данного соглашения, не используйте сайт
                        https://melaminetrade.ru!</p>
                    <h2 className={styles.subtitle}>Права и обязанности сторон</h2>
                    <p className={styles.bold}>Пользователь имеет право:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>осуществлять поиск информации на сайте</li>
                        <li className={styles.listItem}>получать информацию на сайте</li>
                        <li className={styles.listItem}>использовать информацию сайта в личных некоммерческих целях</li>
                    </ol>
                    <p className={styles.bold}>Администрация имеет право:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>по своему усмотрению и необходимости создавать, изменять,
                            отменять
                            правила
                        </li>
                        <li className={styles.listItem}>ограничивать доступ к любой информации на сайте</li>
                    </ol>
                    <p className={styles.bold}>Пользователь обязуется:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>не нарушать работоспособность сайта</li>
                        <li className={styles.listItem}>не использовать скрипты (программы) для автоматизированного
                            сбора
                            информации и/или взаимодействия с Сайтом и его Сервисами
                        </li>
                    </ol>
                    <p className={styles.bold}>Администрация обязуется:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>поддерживать работоспособность сайта за исключением случаев,
                            когда
                            это невозможно по независящим от Администрации причинам
                        </li>
                        <li className={styles.listItem}>предоставить всю доступную информацию о Пользователе
                            уполномоченным
                            на то органам государственной власти в случаях, установленных законом
                        </li>
                    </ol>
                    <h2 className={styles.subtitle}>Ответственность сторон</h2>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>пользователь лично несет полную ответственность за
                            распространяемую
                            им информацию
                        </li>
                        <li className={styles.listItem}>администрация не несет никакой ответственности за достоверность
                            информации, скопированной из других источников
                        </li>
                        <li className={styles.listItem}>администрация не несёт ответственность за несовпадение ожидаемых
                            Пользователем и реально полученных услуг
                        </li>
                        <li className={styles.listItem}>администрация не несет никакой ответственности за услуги,
                            предоставляемые третьими лицами
                        </li>
                        <li className={styles.listItem}>в случае возникновения форс-мажорной ситуации (боевые действия,
                            чрезвычайное положение, стихийное бедствие и т. д.)
                        </li>
                    </ol>
                    <p>Администрация не гарантирует сохранность информации, размещённой Пользователем, а также
                        бесперебойную
                        работу информационного ресурса</p>
                    <h2 className={styles.subtitle}>Условия действия Соглашения</h2>
                    <p>Данное Соглашение вступает в силу при любом использовании данного сайта. Соглашение действует
                        бессрочно. Администрация оставляет за собой право в одностороннем порядке изменять данное
                        соглашение
                        по своему усмотрению. Администрация не оповещает пользователей об изменении в Соглашении.</p>
                </div>
            </article>
            <Seo
                title='Пользовательское соглашение'
                description='Пользовательское соглашение сайта melaminetrade.ru'
                keywords='меламин трейд пользовательское соглашение'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}