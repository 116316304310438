import styles from './info.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import whiteDust from '../../images/info-summary.png';
import greyDust from '../../images/info-graphite.png';
import { Link } from 'react-router-dom';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function Info() {
    useVH();
    return (
        <>
            <article className={styles.info}>
                <Breadcrumbs />
                <h1 className={styles.title}>Полезная информация</h1>
                <p className={styles.text}>Статьи о свойствах антипиренов, их разновидностях, характеристиках и областях
                    применения.</p>
                <ul className={styles.infoGrid}>
                    <li className={styles.infoItem}>
                        <Link
                            to="/info/fire-retardant"
                            className={styles.link}
                        >
                            <h2 className={styles.infoItemTitle}>Антипирены</h2>
                            <img
                                className={styles.image}
                                src={whiteDust}
                                alt="Белый порошок антипирены"
                            />
                        </Link>
                    </li>
                    <li className={styles.infoItem}>
                        <Link
                            to="/info/melamine"
                            className={styles.link}
                        >
                            <h2 className={styles.infoItemTitle}>Меламин микронизированный</h2>
                            <img
                                className={styles.image}
                                src={whiteDust}
                                alt="Белый порошок меламин"
                            />
                        </Link>
                    </li>
                    <li className={styles.infoItem}>
                        <Link
                            to="/info/polyphosphate"
                            className={styles.link}
                        >
                            <h2 className={styles.infoItemTitle}>Полифосфат аммония</h2>
                            <img
                                className={styles.image}
                                src={whiteDust}
                                alt="Белый порошок полифосфат аммония"
                            />
                        </Link>
                    </li>
                    <li className={styles.infoItem}>
                        <Link
                            to="/info/graphite"
                            className={styles.link}
                        >
                            <h2 className={styles.infoItemTitle}>Терморасширяющийся графит</h2>
                            <img
                                className={styles.image}
                                src={greyDust}
                                alt="Серый порошок терморасширяющийся графит"
                            />
                        </Link>
                    </li>
                </ul>
            </article>
            <Seo
                title='Полезная информация'
                description='Статьи о свойствах антипиренов, их разновидностях, характеристиках и областях применения.'
                keywords='антипирены информация'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}