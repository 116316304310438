import styles from './footer.module.css';
import { Link } from 'react-router-dom';
import { getCurrentYear } from '../../helpers/get-current-year/get-current-year';

export function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <ul className={styles.footerItem}>
                    <li className={styles.footerInfo}>{getCurrentYear()} г. ООО «Меламин Трейд»</li>
                    <li className={styles.footerInfo}><a
                        className={styles.footerLink}
                        href="https://rjadysh.com?utm_source=melaminetrade&utm_medium=footer&utm_campaign=development"
                        target='_blank'
                        rel='noreferrer'
                    >Разработка и поддержка сайта «Рядышком»</a></li>
                </ul>
                <ul className={styles.footerItem}>
                    <li className={styles.footerInfo}>140250, Воскресенск, г. Белоозёрский, ул. Молодежная, д. 24</li>
                    <li className={styles.footerInfo}><a
                        href="mailto:order@melaminetrade.ru"
                        className={styles.footerLink}
                    >order@melaminetrade.ru</a></li>
                </ul>

                <ul className={styles.footerItem}>
                    <li className={styles.footerInfo}><Link
                        className={styles.footerLink}
                        to='/terms-and-conditions'
                    >Пользовательское соглашение</Link></li>
                    <li className={styles.footerInfo}><Link
                        className={styles.footerLink}
                        to='/privacy-policy'
                    >Политика конфиденциальности</Link></li>
                </ul>
            </div>
        </footer>
    )
}
