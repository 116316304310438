import styles from './404.module.css';
import { Breadcrumbs } from "../../components/breadcrumbs/Breadcrumbs";
import notFoundImage from '../../images/component-404.jpg';
import { Link } from 'react-router-dom';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function NotFound() {
    useVH();
    return (
        <>
            <article className={styles.notFound}>
                <Breadcrumbs />
                <h1 className={styles.title}>Ошибка 404</h1>
                <h2 className={styles.subtitle}>Страница не найдена!</h2>
                <div className={styles.notFoundContainer}>
                    <div className={styles.info}>
                        <p className={styles.caption}>Мы сожалеем, но страница на которую Вы пытались перейти не
                            найдена.
                            Пожалуйста, перейдите на главную страницу или воспользуйтесь меню сайта.</p>
                        <Link
                            className={styles.button}
                            to='/'
                        >На главную страницу</Link>
                    </div>
                    <img
                        className={styles.image}
                        src={notFoundImage}
                        alt="Грустное яичко"
                    />
                </div>
            </article>
            <Seo
                title='Ошибка 404'
                description='Мы сожалеем, но страница на которую Вы пытались перейти не найдена.'
                keywords='меламинтрейд антипирены 404'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}