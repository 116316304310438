import styles from './products.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { products } from '../../data/products';
import { Product } from '../../components/product/Product';
import { More } from '../../components/more/More';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function Products() {
    useVH();
    return (
        <>
            <article className={styles.products}>
                <Breadcrumbs />
                <h1 className={styles.title}>Антипирены</h1>
                <div className={styles.productsGrid}>
                    {
                        Object.values(products).map((product, key) => (
                            <Product
                                product={product}
                                key={key}
                            />
                        ))
                    }
                    <article className={styles.info}>
                        <p className={styles.caption}><span className={styles.bold}>Антипирены</span> — это химические
                            вещества или соединения, ингибиторы реакции горения. Они
                            препятствуют воспламенению и возгоранию, не дают пламени распространяться, способствуют
                            самостоятельному затуханию огня.
                        </p>
                        <More
                            href={'/info/fire-retardant'}
                            caption='Читать полностью'
                        />
                    </article>
                </div>
            </article>
            <Seo
                title='Антипирены'
                description='Антипирены оптом Россия. Доставка, самовывоз.'
                keywords='антипирены, полифосфат аммония, меламин, терморасширительный графит, трг'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}