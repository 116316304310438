import styles from './download.module.css';

export function Download({ file, caption }) {
    return (
        <a
            className={styles.download}
            href={file}
            target='_blank'
            rel='noreferrer'
        >{caption}</a>
    )
}