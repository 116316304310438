import { useState, useEffect } from 'react';

export function useModal() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        function closeByEsc(e) {
            if (e.key === 'Escape') {
                setIsVisible(false)
            }
        }

        document.addEventListener('keydown', closeByEsc)
        return () => document.removeEventListener('keydown', closeByEsc)
    })

    function toggleModal() {
        setIsVisible(!isVisible);
    }

    return {
        isVisible,
        setIsVisible,
        toggleModal
    }
}