import styles from './article.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Seo } from '../../helpers/Seo';

export function MelamineArticle() {
    return (
        <>
            <article className={styles.article}>
                <Breadcrumbs />
                <h1 className={styles.title}>Меламин</h1>
                <div className={styles.articleContainer}>
                    <p><span className={styles.bold}>Меламин</span> (микронизированный) является невоспламеняющимся, не
                        имеющим запаха, низкотоксичным и
                        неопасным химическим веществом.</p>
                    <p>Меламин необходим для получения органических смол, дубящих веществ, гербицидов и красителей, а
                        также
                        в других процессах промышленного органического синтеза. Основная его масса используется в
                        изготовлении меламин-формальдегидных смол – соединений с очень широким применением.</p>
                    <p>
                        <span className={styles.bold}>Меламин является важным компонентом при изготовлении пластмасс,</span> плит
                        МДФ, лакокрасочных материалов и
                        вспучивающихся огнезащитных покрытий. Варианты его применения исчисляются сотнями. В том числе,
                        он
                        нужен для:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Производства смол, применяемых при изготовлении ДСП, фанеры,
                            ламината, бумажно-слоистых
                            пластиков для облицовки мебели, стен, салонов транспортных средств, кабин лифтов,
                            производстве
                            клеев, пластификаторов бетона.
                        </li>
                        <li className={styles.listItem}>Изготовления высококачественного бетона, пропиточных смол и
                            водостойких клеев.
                        </li>
                        <li className={styles.listItem}>Повышения сопротивляемости бумаги намоканию и истиранию,
                            например,
                            при печатании денег и документов.
                        </li>
                        <li className={styles.listItem}>Дубления кожи.</li>
                        <li className={styles.listItem}>Производства пропиток тканей, обеспечивающих им стабильность
                            размеров, стойкость к абразивному воздействию и снижение воспламеняемости.
                        </li>
                        <li className={styles.listItem}>Изготовления санитарно-гигиенической продукции и посуды.</li>
                        <li className={styles.listItem}>При производстве лакокрасочных средств, с хорошей
                            цветоустойчивостью, высоким сопротивлением к износу, царапанию, истиранию, применяются они
                            при
                            окраске автомобилей, бытовой техники.
                        </li>
                        <li className={styles.listItem}>Выпуска деталей автомобилей и станков, стойких к электрическому
                            пробою и образованию искр.
                        </li>
                    </ol>
                    <p>Многие изделия технического и бытового назначения имеют в своем составе меламин и сетчатые
                        полимеры
                        из его смол, которые обладают целым рядом уникальных свойств:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Высокая прочность.</li>
                        <li className={styles.listItem}>Теплостойкость.</li>
                        <li className={styles.listItem}>Водостойкость.</li>
                        <li className={styles.listItem}>Устойчивость к воздействию ультрафиолета.</li>
                        <li className={styles.listItem}>Износостойкость.</li>
                    </ol>
                    <p>Кроме того все изделия из меламина и его производных гораздо менее токсичны, а также прочнее,
                        более
                        теплостойкие, активно противостоят воздействию огнем, имеют отличные декоративные качества и
                        могут
                        быть окрашены в любые цвета.</p>
                    <p><span className={styles.bold}>Внешний вид:</span> порошок белого цвета</p>
                    <h2 className={styles.subtitle}>Технические характеристики</h2>
                    <div
                        className={styles.table}
                        role='table'
                    >
                        <div className={styles.row}>
                            <p className={styles.bold}>Наименование показателей:</p>
                            <p className={styles.bold}>Значение показателей:</p>
                        </div>
                        <div className={styles.row}>
                            <p>Содержание меламина, %, не менее:</p>
                            <p>99,8</p>
                        </div>
                        <div className={styles.row}>
                            <p>Температура плавления, ºС:</p>
                            <p>354</p>
                        </div>
                        <div className={styles.row}>
                            <p>Температура сублимации, °С:</p>
                            <p>300</p>
                        </div>
                        <div className={styles.row}>
                            <p>Растворимость в воде (20 °С), не более, г/л:</p>
                            <p>3,3</p>
                        </div>
                        <div className={styles.row}>
                            <p>Плотность, г/см³:</p>
                            <p>1,573</p>
                        </div>
                        <div className={styles.row}>
                            <p>Влажность, %, не более:</p>
                            <p>0,1</p>
                        </div>
                        <div className={styles.row}>
                            <p>Зола %, не более:</p>
                            <p>0,03</p>
                        </div>
                        <div className={styles.row}>
                            <p>pH:</p>
                            <p>7,5-9,5</p>
                        </div>
                        <div className={styles.row}>
                            <p>Средний размер частиц, мкм, не более:</p>
                            <p>40</p>
                        </div>
                    </div>
                    <p>Меламин практически не растворим в воде, температура его плавления составляет 354 градусов
                        Цельсия,
                        практически не растворяется в горячем этиловом спирте, бензоле и тетрахлориде углерода;
                        растворяется
                        в метаноле, формальдегиде, уксусной кислоте, горячем этиленгликоле, глицероле, пиридине и прочих
                        веществах.</p>
                    <p>Получить меламин можно из мочевины, в присутствии достаточно высокой температуры и давления.
                        Кроме
                        того применяют метод синтеза меламина из дициандиамида. Меламин может быть опасен для
                        человеческого
                        организма при проглатывании, контакте с кожей и вдыхании.</p>
                </div>
            </article>
            <Seo
                title='Статьи: Меламин'
                description='Меламин необходим для получения органических смол, дубящих веществ, гербицидов и красителей, а также в других процессах промышленного органического синтеза.'
                keywords='меламин информация'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}