import styles from './more.module.css';
import { Link } from 'react-router-dom';

export function More({ href, caption }) {
    return (
        <Link
            className={styles.more}
            to={href}
            target='_self'
        >{caption}</Link>
    )
}