import styles from './breadcrumbs.module.css';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from "react-router-dom";

const routes = [
    { path: '/', breadcrumb: 'Главная ' },
    { path: '/about', breadcrumb: 'О компании' },
    { path: '/products', breadcrumb: 'Товары' },
    { path: '/products/polyphosphate', breadcrumb: 'Полифосфат аммония' },
    { path: '/products/melamine', breadcrumb: 'Меламин' },
    { path: '/products/graphite', breadcrumb: 'Термо расширяющийся графит' },
    { path: '/payment', breadcrumb: 'Доставка и оплата' },
    { path: '/certificates', breadcrumb: 'Сертификаты' },
    { path: '/info', breadcrumb: 'Полезная информация' },
    { path: '/info/fire-retardant', breadcrumb: 'Антипирены' },
    { path: '/info/melamine', breadcrumb: 'Меламин' },
    { path: '/info/polyphosphate', breadcrumb: 'Полифосфат аммония' },
    { path: '/info/graphite', breadcrumb: 'Термо расширяющийся графит' },
    { path: '/contacts', breadcrumb: 'Контакты' },
    { path: '/terms-and-conditions', breadcrumb: 'Пользовательское соглашение' },
    { path: '/privacy-policy', breadcrumb: 'Политика конфиденциальности' },
];

export function Breadcrumbs() {
    const breadcrumbs = useBreadcrumbs(routes);

    return (
        <div className={styles.breadcrumbs}>
            {breadcrumbs.map(({
                                  match,
                                  breadcrumb,
                                  key
                              }) => (
                <NavLink
                    className={styles.link}
                    key={match.pathname}
                    to={match.pathname}
                >{breadcrumb}</NavLink>
            ))}
        </div>
    );
}