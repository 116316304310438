import styles from './article.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Seo } from '../../helpers/Seo';

export function GraphiteArticle() {
    return (
        <>
            <article className={styles.article}>
                <Breadcrumbs />
                <h1 className={styles.title}>Термо расширяющийся графит (ТРГ)</h1>
                <div className={styles.articleContainer}>
                    <p><span className={styles.bold}>Терморасширяющийся графит</span> (ТРГ) представляет собой
                        модифицированный природный графит, очищенный от
                        смол
                        и неорганических наполнителей. Состоит из чистого углерода с незначительным содержанием
                        примесей.
                        Чем меньше примесей, тем выше качество ТРГ.</p>
                    <p>Производство ТРГ можно разделить на несколько этапов:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Исходный кристаллический графит окисляют путем введения серной
                            или
                            азотной кислоты в присутствии
                            окислителя (перекись водорода, перманганат калия и др.) Окисленный графит отмывают и сушат.
                        </li>
                        <li className={styles.listItem}>Окисленный графит нагревают со скоростью 400-600 °С/с. Благодаря
                            этому процессу из графита
                            удаляется введенная на первом этапе кислота и достигается необходимая для дальнейшей
                            обработки
                            чешуйчатая структура вещества.
                        </li>
                        <li className={styles.listItem}>Полученный терморасширяющийся графит прокатывают, иногда
                            армируют,
                            добавляют присадки и прессуют
                            для получения изделий.
                        </li>
                    </ol>
                    <p>
                        <span className={styles.bold}>Терморасширяющийся графит – уникальный во многих отношениях материал.</span> Практически
                        на 100% он состоит
                        из
                        чистого углерода, что и делает его востребованным в промышленных отраслях. Материал имеет
                        незначительные включения других химических элементов, однако они не влияют на конечные
                        характеристики.</p>
                    <p>Уникальность данного материала обусловлена его хорошей пластичностью. Им успешно заменяют
                        уплотнители
                        и набивки из других материалов, представленных на рынке. Многие производители отказываются от
                        асбестосодержащих материалов, фторопласта и резины в пользу терморасширяющегося графита. Вот
                        лишь
                        несколько причин:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>ТРГ отличается устойчивостью к большинству агрессивных сред. Его
                            можно использовать в кислотных
                            и щелочных жидкостях, а также в органических растворителях и нефтесодержащих средах.
                        </li>
                        <li className={styles.listItem}>Обладает низким коэффициентом трения, поэтому не деформирует
                            поверхности, с которыми
                            контактирует.
                        </li>
                        <li className={styles.listItem}>Выдерживает температуры в очень большом диапазоне, однако при
                            нагреве свыше 3300 °С подвергается
                            сублимации.
                        </li>
                        <li className={styles.listItem}>Имеет длительный срок эксплуатации за счёт высокой химической и
                            механической стойкости. Время
                            хранения составляет около 10 лет.
                        </li>
                        <li className={styles.listItem}>Материал нетоксичен.</li>
                        <li className={styles.listItem}>ТРГ не поддерживает горение.</li>
                    </ol>
                    <p>В отличие от асбестосодержащих материалов, ТРГ сохраняет первоначальную пластичность и упругость
                        на
                        протяжении всего времени использования. Если паронит и асбест со временем теряют объём и массу и
                        нуждаются в дополнительном уплотнении, то ТРГ это несвойственно.</p>
                    <p>В структуре ТРГ содержится не более 5% золы, всё остальное – связанный углерод. Причём чем выше
                        процент углерода, тем лучше и качественнее материал. Так, в атомной промышленности допускается
                        использование терморасширяющегося графита с общим содержанием золы не более 0,1%.</p>
                    <p>Как и всякий материал, ТРГ имеет некоторые ограничения в ходе эксплуатации. Изделия из него
                        нельзя
                        устанавливать в средах с сильными кислотами, например, серной, хромовой и азотной.</p>
                    <p>Работоспособность изделий из данного графита ограничена давлением до 40 МПа. В специальных
                        конструкция возможно увеличение рабочего давления до 100 МПа.</p>
                    <p><span className={styles.bold}>Терморасширяющийся графит нашёл широкое применение</span> в таких
                        отраслях промышленности как нефтегазовая,
                        химическая, в топливно-энергетическом комплексе, коммунальном хозяйстве, машиностроении и многих
                        других областях. Также терморасширяющемуся графиту присущи уникальные антифрикционные и
                        электротехнические свойства. Таким образом, ТРГ является на сегодняшний день уникальным
                        материалом,
                        что способствует устойчивому росту потребления уплотнений на его основе многими отраслями
                        промышленности.</p>
                    <p>Именно возможность получения ТРГ с определенными физико-химическими свойствами, позволяющими
                        изготавливать из него жаростойкие и химически инертные изделия (гибкую графитовую фольгу и
                        уплотнения на ее основе) вызвало промышленное освоение технологий синтеза ИГ и получения
                        ТРГ.</p>
                    <p>Некоторые виды уплотнений (набивки, многослойные прокладки и др.) изготавливают с применением
                        связующих и армирующих элементов (металлических, полимерных и др.), а также модифицирующих
                        элементов
                        (ингибиторов коррозии или окисления, антиадгезионных добавок и покрытий и др.).</p>
                    <p>Основным применением терморасширяющегося графита является производство фольги ТРГ и графитового
                        прокладочного материала, а также графитовых уплотнений на их основе, таких как уплотнительные
                        прокладки разных типов, сальниковые кольца, плетеные набивки и др. </p>
                    <p className={styles.bold}>Существуют данные о применении ТРГ и материалов из него в качестве:</p>
                    <ol className={styles.list}>
                        <li className={styles.listItem}>Теплоизоляционных материалов (тепловых экранов вакуумных печей и
                            др.)
                        </li>
                        <li className={styles.listItem}>Наполнителя термопластичных и термореактивных полимеров
                            (полиамидов,
                            полиэфиров, полиэтилена,
                            полипропилена) при изготовлении композитов с низким или анизотропным электросопротивлением
                            (обивочных материалов, свободных от статического электричества.
                        </li>
                        <li className={styles.listItem}>Материалов для защиты от электромагнитных излучений);
                            составляющей
                            пластичных антифрикционных смазок, работоспособных в широком интервале температур и нагрузок
                            в
                            течение длительного времени.
                        </li>
                        <li className={styles.listItem}>Углеродной компоненты при реакционном спекании керамики на
                            основе
                            карбида кремния.
                        </li>
                        <li className={styles.listItem}>Носителя катализатора; сорбента для сбора минеральных масел и
                            нефти,
                            в т.ч. в случае аварийных разливов на поверхности воды; мембран (в виде самопрессованного
                            упрочненного ТРГ).
                        </li>
                        <li className={styles.listItem}>Фильтров (в виде низкоплотных матов из ТРГ).</li>
                        <li className={styles.listItem}>Резьбовых лент и т.д.</li>
                    </ol>
                    <p>Уникальное сочетание эксплуатационных свойств терморасширяющегося графита (ТРГ), таких как
                        широкий
                        диапазон рабочих температур, высокая химическая стойкость, прекрасная уплотняющая способность,
                        способствуют устойчивому росту потребления уплотнений на его основе многими отраслями
                        промышленности.</p>
                </div>
            </article>
            <Seo
                title='Статьи: Термо расширяющийся графит (ТРГ)'
                description='Терморасширяющийся графит (ТРГ) представляет собой модифицированный природный графит, очищенный от смол и неорганических наполнителей.'
                keywords='терморасширяющийся графит (трг) информация'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}